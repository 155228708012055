import { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login";
import FacebookLogo from "../../assets/Facebook.svg";
import { api } from "../../services/api";
import "./styles.css";

export function Home() {
  const saveUrlData = () => {
    const paramsUrl = new URLSearchParams(window.location.search);
    const id = paramsUrl.get("id");
    id && localStorage.setItem("id", id);
    const deviceToken = paramsUrl.get("deviceToken");
    deviceToken && localStorage.setItem("deviceToken", deviceToken);
  };
  const getUrlData = () => {
    const id = localStorage.getItem("id");
    const deviceToken = localStorage.getItem("deviceToken");
    setResponse("id:" + id + " deviceToken: " + deviceToken);
    return { id, deviceToken };
  };

  useEffect(() => {
    saveUrlData();
    // getUrlData();
  }, []);

  const postDataOnApi = async (data: any) => {
    const { id, deviceToken } = getUrlData();

    const payload = {
      name: data.name,
      email: data.email,
      phone: data.phone ?? "",
      nickname: data.name.split(" ")[0],
      deviceToken: deviceToken,
    };

    await api.post("login-social/update", {
      id: id,
      data: JSON.stringify(payload),
      deviceToken: deviceToken || "",
      // expiration: "2023-01-30",
    });
    setText("Login realizado com sucesso! Pode fechar a janela.");
    return true;
  };
  const responseFacebook = async (response: any) => {
    setLoading(true);
    localStorage.setItem("token", JSON.stringify(response));

    setUserData(JSON.stringify(response));
    setTimeout(async () => {
      const data = await postDataOnApi(response);
      setLoading(false);
      window.close();
    }, 3000);
  };

  const [data, setUserData] = useState<any>("");
  const [response, setResponse] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");

  return (
    <div className="container-facebook">
      <header className="header-facebook">
        <img src={FacebookLogo} alt="Logo" className="facebook-logo" />
      </header>
      {text ? (
        <h1>{text}</h1>
      ) : loading ? (
        <div className="loading">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <FacebookLogin
          appId="2119584291567757"
          buttonStyle={{
            backgroundColor: "#4267b2",
            color: "#fff",
            fontSize: "16px",
            padding: "12px 24px",
            border: "none",
            borderRadius: "4px",
          }}
          textButton="Login Facebook"
          // autoLoad={true}
          redirectUri="https://10.0.0.18:3000"
          // isMobile={false}
          // fields="name,email,picture"
          fields="name,email"
          callback={responseFacebook}
        />
      )}
    </div>
  );
}

// import { FacebookProvider, useLogin } from "react-facebook";

// export const Home = () => {
//   const { login, status, isLoading, error } = useLogin();

//   async function handleLogin() {
//     try {
//       const response = await login({
//         scope: "email",
//       });

//       console.log(response.status);
//     } catch (error: any) {
//       console.log(error.message);
//     }
//   }

//   return (
//     <button onClick={handleLogin} disabled={isLoading}>
//       Login via Facebook
//     </button>
//   );
// };
