import { useEffect, useState } from "react";
import GoogleLogo from "../../assets/GoogleLogo.svg";

import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { api } from "../../services/api";
import "./styles.css";

export function Google() {
  const [data, setUserData] = useState<any>("");
  const [response, setResponse] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");

  const clientID = "645985146450-umj34ma0atjqucel923b1j9e62l9cfr3";

  const saveUrlData = () => {
    const paramsUrl = new URLSearchParams(window.location.search);
    const id = paramsUrl.get("id");
    id && localStorage.setItem("id", id);
    const deviceToken = paramsUrl.get("deviceToken");
    deviceToken && localStorage.setItem("deviceToken", deviceToken);
  };
  const getUrlData = () => {
    const id = localStorage.getItem("id");
    const deviceToken = localStorage.getItem("deviceToken");
    setResponse("id:" + id + " deviceToken: " + deviceToken);
    return { id, deviceToken };
  };

  useEffect(() => {
    saveUrlData();
    // getUrlData();
  }, []);

  const postDataOnApi = async (data: any) => {
    const { id, deviceToken } = getUrlData();

    const payload = {
      name: data?.name,
      email: data?.email,
      phone: data?.phone ?? "",
      nickname: data?.givenName,
      imageUrl: data?.imageUrl,
      deviceToken: deviceToken,
    };

    await api.post("login-social/update", {
      id: Number(id),
      data: JSON.stringify(payload),
      deviceToken: deviceToken || "",
      // expiration: "2023-01-30", 
    });
    return true;
  };
  const responseGoogle = async (response: any) => {
    setLoading(true);
    localStorage.setItem("token", JSON.stringify(response));

    setUserData(JSON.stringify(response));
    setTimeout(async () => {
      const data = await postDataOnApi(response);
      setText("Login realizado com sucesso! Pode fechar a janela.");
      setLoading(false);
      window.close();
    }, 2000);
  };

  const onSuccess = (res: any) => {
    console.log("success:", res.profileObj);
    responseGoogle(res.profileObj);
  };
  const onFailure = (err: any) => {
    console.log("failed:", err);
  };

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientID,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);

  return (
    <div className="container-google">
      <header className="header-google">
        <img src={GoogleLogo} alt="Logo" className="google-logo" />
      </header>
      {text ? (
        <h1>{text}</h1>
      ) : loading ? (
        <div className="loading">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <GoogleLogin
          clientId={clientID}
          buttonText="Sign in with Google"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={"single_host_origin"}
          isSignedIn={false}
        />
      )}
    </div>
  );
}

// import { FacebookProvider, useLogin } from "react-facebook";

// export const Home = () => {
//   const { login, status, isLoading, error } = useLogin();

//   async function handleLogin() {
//     try {
//       const response = await login({
//         scope: "email",
//       });

//       console.log(response.status);
//     } catch (error: any) {
//       console.log(error.message);
//     }
//   }

//   return (
//     <button onClick={handleLogin} disabled={isLoading}>
//       Login via Facebook
//     </button>
//   );
// };
